<template>
  <div class="card">
    <div v-if="showComingSoon">
      <h1 class="header-text pb-3" data-cy="header-admin-home">Coming Soon</h1>
      Mentor X Log is currently closed as we work to place students with mentors.
      We look forward to see you at the end of August when Mentor X Log
      opens for you to see your mentor’s information and begin your fieldwork.
    </div>
    <div v-else-if="showGraduatedInformation">
      <h1 class="header-text pb-3" data-cy="header-admin-home">Graduated</h1>
      Congrats on a job well done!
    </div>
    <div v-else>
      <h1 class="header-text pb-3" data-cy="header-admin-home">Summer Home</h1>
      <div v-if="showProfileEditInformation" class="box-style mb-4">
        <div class="grid grid-cols-2 gap-4">
          <h2 class="secondhead-text">Profile Preferences</h2>
          <div class="text-right">
            <Button
              text="Edit Profile"
              @click="this.$router.push('/summer/profile/edit')"
            />
          </div>
        </div>
        <div class="text-dark-gray">
          <template v-if="this.$route.query?.preferences !== 'updated'">
            Please update your profile information and mentor preferences if you
            have not done this.
          </template>
          <template v-else>
            <error-banner
              class="mt-2"
              title="Success"
              :messages="[
                'We look forward to working with you at the end of August when Mentor X Log opens for you to see your mentor’s information and begin your fieldwork.',
              ]"
              success
            />
          </template>
        </div>
      </div>

      <div class="box-style" v-if="showSurveyInformation">
        <div class="">
          <h2 class="secondhead-text">Mentor Survey</h2>
          <div class="text-dark-gray">
            If you have not already, please complete your mentor survey.
          </div>
          <div
            v-for="mentor in mentors.sort(
              (a, b) => a.lastname > b.lastname
            )"
            :key="mentor.mentor_id"
            class="text-right mb-2"
          >
            <Button
              :text="`Mentor Survey for ${mentor.prefix} ${mentor.firstname} ${mentor.lastname}`"
              @click="this.$router.push('/summer/mentor/eval/'+mentor.mentor_id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";
import ErrorBanner from "@/components/forms/ErrorBanner";
import { parseDate } from "@/composables/date_utils";
import { get } from "@/composables/httpUtil";

export default {
  name: "SummerHome",
  components: {
    Button,
    ErrorBanner,
  },
  data() {
    return {
      now: new Date(),
      showSurveyInformation: false,
      showProfileEditInformation: false,
      showGraduatedInformation: false,
      showComingSoon: false,
      mentors: [],
    };
  },
  created() {
    if (
      this.lookup_current_term.term_id === this.getAcadLevel.term.term_id &&
      this.now >
        parseDate(this.lookup_current_term.milestones.pairing_starts_date)
    ) {
      this.showComingSoon = true;
    }
    switch (this.getStudentStatus()) {
      case "new":
        this.showProfileEditInformation = true;
        break;

      case "graduated":
        this.showGraduatedInformation = true;
        break;

      case "existing":
      default:
        this.showProfileEditInformation = true;
        this.showSurveyInformation = true;
    }
    let term_to_search = "";
    if (this.lookup_current_term.term_id === this.getAcadLevel.term.term_id &&
        this.now >
        parseDate(this.lookup_current_term.milestones.pairing_starts_date)) {
      term_to_search = this.lookup_current_term.term_id;
    } else {
      term_to_search = this.lookup_previous_term.term_id;
    }
    let mentor_level = this.getStudentLevels.find((l) => {
      return l.term.term_id === term_to_search;
    });
    const url = `student/mentors?student_at_level=${mentor_level.student_at_level_id}`;
    get(url).then(
      (response) => {
        this.mentors = response;
        this.update_summer_mentors(this.mentors);
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  },
  methods: {
    get,
    getStudentStatus() {
      return localStorage.getItem("student_status");
    },
    ...mapMutations(["update_summer_mentors"]),
  },
  computed: {
    ...mapGetters([
      "lookup_current_term",
      "lookup_next_term",
      "lookup_previous_term",
      "getAcadLevel",
      "getStudentLevels",
    ]),
  },
};
</script>
<style scoped></style>
